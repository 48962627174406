<template>
	<b-row class="animated fadeIn d-flex flex-row w-100 px-4" :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
		<div style="width: 98%">
			<b-row class="w-100">
				<b-col sm="3" md="3" lg="3" xl="3">
					<b-form-group :label="FormMSG(4, 'Bank Name')">
						<b-form-input
							type="text"
							autocomplete="off"
							v-model="bank.bankName"
							:class="{
								'is-invalid': $v.bank.bankName.$error
							}"
							@keyup="handleInputChanged('bankName')"
						/>
					</b-form-group>
				</b-col>
				<b-col sm="4" md="4" lg="4" xl="4">
					<b-form-group :label="FormMSG(1, 'IBAN')">
						<b-form-input
							type="text"
							autocomplete="off"
							v-model="bank.iban"
							:class="{
								'is-invalid': $v.bank.iban.$error
							}"
							@keyup="handleInputChanged('iban')"
						/>
					</b-form-group>
				</b-col>
				<b-col sm="3" md="3" lg="3" xl="3">
					<b-form-group :label="FormMSG(3, 'BIC')">
						<b-form-input type="text" autocomplete="off" v-model="bank.bic" @keyup="handleInputChanged('bic')" />
					</b-form-group>
				</b-col>
				<b-col sm="2" md="2" lg="2" xl="2">
					<b-form-group :label="FormMSG(2, 'Currency')">
						<b-form-select v-model="bank.currency" :options="currencies" @change="handleInputChanged('currency', 1)" />
					</b-form-group>
				</b-col>
			</b-row>
		</div>
		<div style="width: 2%; margin-left: -10px" class="d-flex align-items-center">
			<component
				:is="getLucideIcon('MinusCircle')"
				color="#EA4E2C"
				class="cursor-pointer"
				:size="18"
				:stroke-width="3"
				style="margin-left: -8px; margin-top: 12px"
				@click="removeItem"
			/>
		</div>
	</b-row>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import _ from 'lodash';
import { INPUT_TYPE } from '@/shared/constants';

export default {
	name: 'FinanceBankForm',
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	props: {
		item: {
			type: Object,
			required: false,
			default: () => {}
		},
		index: {
			type: Number,
			required: false,
			default: 0
		},
		supplier: {
			type: Object,
			required: false,
			default: () => {}
		}
	},
	computed: {
		currencies() {
			return this.FormMenu(5);
		}
	},
	data() {
		return {
			bank: null
		};
	},
	methods: {
		removeItem() {
			// console.log({ bank: this.bank });
			this.$emit('supplier-bank-form:removed', {
				index: this.index,
				id: +this.bank.id,
				supplier: this.supplier
			});
		},
		handleInputChanged(fieldName, type = 0) {
			if (type === INPUT_TYPE.INTEGER) {
				this.bank[fieldName] = +this.bank[fieldName];
			} else if (type === INPUT_TYPE.FLOAT) {
				this.bank[fieldName] = parseFloat(this.bank[fieldName]);
			}

			this.$emit('finance-bank-form:updated', {
				index: this.index,
				item: this.bank,
				isLineValid: !this.$v.$invalid,
				supplier: this.supplier
			});
		}
	},
	watch: {
		item: {
			handler(value) {
				if (!_.isNil(value)) {
					this.bank = _.cloneDeep(value);
					this.$v.$touch();
				}
			},
			deep: true,
			immediate: true
		}
	},
	validations() {
		return {
			bank: {
				iban: {
					required,
					min: minLength(3)
				},
				bankName: {
					required,
					min: minLength(3)
				}
			}
		};
	}
};
</script>
<style lang="scss" scoped></style>

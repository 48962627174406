<template>
	<div :id="supplierSelectorRef" :ref="supplierSelectorRef" class="select_items_filter_wrapper">
		<label for="slectfilter_component_elem" class="d-block" v-if="showDefaultLabel">
			{{ label }}
		</label>
		<b-row>
			<b-col :md="!hideAction && !useNewDesign ? 8 : 12" :lg="!hideAction && !useNewDesign ? 8 : 12" :class="`${useNewDesign ? 'd-flex' : ''}`">
				<v-select
					:id="`${useNewDesign ? 'supplier-selector' : ''}`"
					v-model="selectedSupplier"
					:key="supplierKey"
					:placeholder="placeholder"
					:reduce="(option) => option.code"
					:options="mapItems"
					:disabled="disabled"
					@input="handleChange"
					:class="{ 'grow-flex-auto': useNewDesign && version === '1.0', 'border-radius-supplier': !hideAction }"
				>
					<template #selected-option="{ label }" v-if="version === '2.0'">
						<!-- this version doesn't work (about event) -->
						<div class="d-flex align-items-center parent-selected-option">
							<div class="selected-option-label" style="color: rgba(6, 38, 62, 0.84)">{{ label }}</div>
							<div class="cursor-pointer px-1" :title="FormMSG(8, 'Update')" v-b-tooltip.hover.top @click.stop.prevent="openEditSupplierModal()">
								<component :is="getLucideIcon('Edit')" color="#225CBD" :size="18" :stroke-width="2.25" />
							</div>
							<div
								class="cursor-pointer px-1"
								:title="FormMSG(9, 'Map')"
								v-b-tooltip.hover.top
								@click.stop.prevent="isMappedSupplierModalOpen = true"
							>
								<component :is="getLucideIcon('Map')" color="rgba(6, 38, 62, 0.5)" :size="18" :stroke-width="2.25" />
							</div>
						</div>
					</template>
				</v-select>
				<div class="d-flex flex-row icon-group-border-append" v-if="!hideAction && useNewDesign">
					<div
						:style="`width: ${defaultIconWidth}`"
						class="cursor-pointer d-inline-flex justify-content-center align-items-center"
						v-b-tooltip.hover.top
						:title="FormMSG(7, 'Add')"
						@click="openAddSupplierModal()"
						v-if="(version === '1.0' && !isModifiable) || version === '2.0'"
					>
						<component :is="getLucideIcon('PlusCircle')" color="#00A09C" :size="18" :stroke-width="2.25" />
					</div>
					<div
						:style="`width: ${defaultIconWidth}`"
						class="cursor-pointer d-inline-flex justify-content-center align-items-center"
						v-b-tooltip.hover.top
						:title="FormMSG(8, 'Update')"
						@click="openEditSupplierModal()"
						v-else
					>
						<component :is="getLucideIcon('Edit')" color="#225CBD" :size="18" :stroke-width="2.25" />
					</div>
					<div
						:style="`width: ${defaultIconWidth}`"
						class="cursor-pointer d-inline-flex justify-content-center align-items-center"
						v-b-tooltip.hover.top
						:title="FormMSG(9, 'Map')"
						@click="isMappedSupplierModalOpen = true"
						v-if="version === '1.0' && isModifiable && showMap"
					>
						<component :is="getLucideIcon('Map')" color="rgba(6, 38, 62, 0.5)" :size="18" :stroke-width="2.25" />
					</div>
				</div>
			</b-col>
			<b-col v-if="!hideAction && !useNewDesign" md="4" lg="4" :class="`${$screen.width <= 992 ? 'mt-2' : ''}`">
				<div class="actions_holder">
					<b-button
						class="map-btn"
						v-if="isModifiable && showMap"
						:disabled="mapDisabled"
						@click="isMappedSupplierModalOpen = true"
						v-b-tooltip.hover.top
						:title="FormMSG(9, 'Map')"
					>
						<component :is="getLucideIcon('Map')" color="#FFF" :size="18" :stroke-width="2" style="margin-left: -4px; margin-top: -3px" />
					</b-button>
					<div class="supplier_actions_btns has_two_btn" :class="`${!isModifiable ? 'w-100' : ''}`">
						<b-button :disabled="disabled" @click="!isModifiable ? openAddSupplierModal() : openEditSupplierModal()">
							<edit color="#FFFFFF" :size="18" v-if="isModifiable" style="margin-top: -3px" />
							<plus-circle color="#FFFFFF" :size="18" style="margin-top: -3px" v-else />
						</b-button>
						<b-button
							block
							variant="outline-primary"
							:disabled="disabled"
							@click="!isModifiable ? openAddSupplierModal() : openEditSupplierModal()"
						>
							{{ !isModifiable ? addlabel : updateLabel }}
						</b-button>
					</div>
				</div>
			</b-col>
		</b-row>
		<SupplierModal
			v-model="isAddressModalOpen"
			:supplier-candidate="supplierCandidate"
			:key="addressModalKey"
			:editable="isModifiable"
			:title="modalTitle"
			:current-search="currSearch"
			:focus-supplier="editSupplierPayload"
			:from-project-config="fromProjectConfig"
			@supplier-name-change="handleSupplierNameChange"
			@submit="handleAddressSubmit"
			@delete-supplier="handleDeleteSupplier"
		/>
		<MappedSuppliersModal
			v-if="!mapDisabled"
			v-model="isMappedSupplierModalOpen"
			:selection="selectedSupplier"
			:suppliers="suppliers"
			:key="supplierKey"
			:placeholder="placeholder"
			@selected="setSelectedSupplier"
		/>
	</div>
</template>

<script>
// import { mapCruds } from "crudx";
import { isNil, omit, makeID, noTilde } from '~utils';
import mapProps from '@/shared/vuePropsMapper';
import { getSupplier, getSuppliers, addSupplier, addAddressSupplier, updateSupplier, deleteSupplier, addUpdSupplierBankAccount } from '@/cruds/suppliers.crud';
import languageMessages from '@/mixins/languageMessages';
import SupplierModal from '@/components/Modals/SupplierModal';
import MappedSuppliersModal from '@/components/Modals/MappedSuppliersModal';
import globalMixin from '@/mixins/global.mixin';
import { PlusCircle, Edit } from 'lucide-vue';
import * as _ from 'lodash';

export default {
	name: 'SupplierSelectorComponent',
	components: { SupplierModal, PlusCircle, Edit, MappedSuppliersModal },
	mixins: [languageMessages, globalMixin],
	props: {
		...mapProps(['disabled', 'mapDisabled', 'hideAction', 'useNewDesign', 'canCrudWithSelect'], {
			type: Boolean,
			required: false,
			default: false
		}),
		...mapProps(['showMap'], {
			type: Boolean,
			required: false,
			default: true
		}),
		fromProjectConfig: {
			type: Boolean,
			default: false,
			required: false
		},
		showMap: {
			type: Boolean,
			default: true,
			required: false
		},
		ocrSuppliers: {
			type: Array,
			required: false,
			default: () => []
		},
		supplierId: {
			type: [Number, String],
			required: false,
			default: 0
		},
		type: {
			type: String,
			required: false,
			default: 'normal'
			// validator: (e) => authorizedType.includes(e),
		},
		placeholder: {
			type: String,
			required: false,
			default: 'Type to search ...'
		},
		label: {
			type: String,
			required: false,
			default: 'Input Name'
		},
		addlabel: {
			type: String,
			required: false,
			default: 'Add'
		},
		updateLabel: {
			type: String,
			required: false,
			default: 'Update'
		},
		showDefaultLabel: {
			type: Boolean,
			required: false,
			default: true
		},
		title: {
			type: String,
			required: false,
			default: 'Add Entity Adress'
		},
		version: {
			type: String,
			required: false,
			default: '1.0' // avalaible version :: 1.0, 2.0 <this version doesn't work properly> (only when useNewDesign=true)
		}
	},
	data() {
		return {
			suppliers: [],
			supplierPayload: {},
			editSupplierPayload: {},
			isModifiable: false,
			selectedSupplier: null,
			currSearch: '',
			supplierCandidate: null,
			isAddressModalOpen: false,
			isEditModalForm: false,
			addressModalKey: makeID(5),
			isMappedSupplierModalOpen: false,
			defaultIconWidth: '34px'
		};
	},
	computed: {
		/**
		 * @return {String}
		 */
		supplierSelectorRef() {
			return `supplier_selector_${makeID(5)}`;
		},
		modalTitle() {
			return isNil(this.title) ? this.FormMSG(1111, 'Add entity address') : this.title;
		},
		mapItems() {
			let res = this.suppliers.map(({ id, name, contactName, companyType }) => ({
				code: id,
				label: name,
				contactName: contactName,
				companyType: companyType
			}));

			res.sort((a, b) => {
				if (noTilde(a.label.trim()).toLowerCase() < noTilde(b.label.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.label.trim()).toLowerCase() > noTilde(b.label.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			const initSupplier = {
				code: null,
				label: this.FormMSG(13, 'Select a supplier'),
				contactName: '',
				companyType: 1
			};

			res = [initSupplier, ...res];

			return res;
		},
		supplierKey() {
			return JSON.stringify(this.suppliers);
		},
		rendSupplierType() {
			//console.log("show supplier type:", this.type);
			const t = isNil(this.type) ? 'normal' : this.type;
			// if (!authorizedType.includes(t)) return null
			switch (t) {
				case 'all':
					return null;
				case 'hotel':
					return 1;
				// break
				case 'electricity':
					return 2;
				// break
				case 'location':
					return 3;
				// break
				case 'owner':
					return 4;
				// break
				case 'licenseOlder':
					return 5;
				case 'client':
					return 6;
				case 'tenant':
					return 7;
				case 'green':
					return 8;
				case 'projectAdmin':
					return 9;
				case 'architect':
					return 10;
				case 'syndic':
					return 11;
				case 'gas':
					return 13;
				case 'water':
					return 14;
				default:
					return 0;
				// break
			}
		}
	},
	watch: {
		/**
		 * @param {Boolean} status
		 */
		isAddressModalOpen(s) {
			this.addressModalKey = makeID(5);
			if (!s) this.handleSupplierModalClose();
		},
		ocrSuppliers(newSuppliers) {
			const vatSuppliers = newSuppliers.filter((d) => d.label === 'VAT').map((d) => d.value);
			const emailSuppliers = newSuppliers.filter((d) => d.label === 'EMAIL').map((d) => d.value);
			// 1st step: looking for matching suppliers by VAT
			const matchingVatSuppliers = this.suppliers.filter((supplier) => vatSuppliers.map((d) => d.vat).indexOf(supplier.vat) > -1);
			const matchingEmailSuppliers = this.suppliers.filter((supplier) => emailSuppliers.indexOf(supplier.email) > -1);
			// handle supplier matching a VAT found in receipt
			if (matchingVatSuppliers.length > 0) {
				const { id, name } = matchingVatSuppliers[0];
				this.setSelectedSupplier({ id, name });
				// if not matching supplier is found using VAT, try with e-mail
			} else if (matchingEmailSuppliers.length > 0) {
				const { id, name } = matchingEmailSuppliers[0];
				this.setSelectedSupplier({ id, name });
				// If no match (neither on VAT nor on email) found, create a supplier based on the VAT number (if any)
			} else if (vatSuppliers.length > 0) {
				const supplierCandidate = vatSuppliers[0];
				const email = emailSuppliers.length > 0 ? emailSuppliers[0] : null;
				supplierCandidate.email = supplierCandidate.email || email || null;
				this.setSupplierCandidate(supplierCandidate);
				this.openAddSupplierModal();
				// If no VAT detected on the ticket
			} else if (emailSuppliers.length > 0) {
				this.setSupplierCandidate({ email: emailSuppliers[0] });
				this.openAddSupplierModal();
			}
		},
		supplierId: {
			handler(n) {
				// console.log('Watch SupplierId: ', n);
				this.initEdit(n);
			},
			deep: true,
			immediate: true
		}
	},
	async created() {
		await this.initSuppliers();
		// await this.initEdit()
	},
	methods: {
		async handleClickDelete(code) {
			await deleteSupplier({ id: parseInt(code) });
			await this.initSuppliers();
			this.setSelectedSupplier(null);
			this.$emit('supplier:unselected');
		},
		async initSuppliers() {
			this.suppliers = await getSuppliers({
				name: null,
				type: this.rendSupplierType
			});
		},
		async initEdit(supplierId) {
			if (isNil(supplierId) || +supplierId === 0) {
				this.selectedSupplier = null;

				return;
			}

			await getSupplier(supplierId)
				.then((record) => {
					this.supplierPayload = record;
				})
				.catch((error) => {
					this.supplierPayload = {};
					this.selectedSupplier = null;
				});

			if (isNil(this.supplierPayload)) return;

			this.setSelectedSupplier({
				id: +supplierId,
				name: this.supplierPayload.name || '',
				email: this.supplierPayload.email || '',
				phone: this.supplierPayload.phone || '',
				contactName: this.supplierPayload.contactName || '',
				companyType: this.supplierPayload.companyType || 1
			});
		},
		/**
		 * @param {Object} supplier
		 * @return {Event}
		 */
		emitChange(supplier) {
			if (isNil(supplier)) return;
			this.$emit('change', supplier);
		},
		/**
		 * @param {Object} supplier
		 */
		setSelectedSupplier(supplier) {
			if (isNil(supplier) || +supplier.id === 0) {
				this.selectedSupplier = null;
				// this.emitChange(null);
				this.isModifiable = false;
			} else {
				const { name, id, email, phone, contactName, companyType } = supplier;
				this.currSearch = name;
				this.emitChange({ id, name, email, phone, contactName, companyType });
				this.selectedSupplier = {
					code: id,
					label: name,
					email,
					phone
				};
				this.isModifiable = true;
			}
		},
		/**
		 * @param {Object} $event
		 */
		async handleChange(payload) {
			this.selectedSupplier = null;
			if (!_.isNil(payload) && +payload > 0) {
				this.isModifiable = true;
				await getSupplier(+payload)
					.then((record) => {
						this.supplierPayload = record;
					})
					.catch((error) => {
						this.supplierPayload = {};
						this.selectedSupplier = null;
					});
				// console.log({supplierPayload: this.supplierPayload})
				this.setSelectedSupplier({
					id: +payload,
					name: this.supplierPayload.name || '',
					email: this.supplierPayload.email || '',
					phone: this.supplierPayload.phone || '',
					contactName: this.supplierPayload.contactName,
					companyType: this.supplierPayload.companyType
				});
			} else {
				this.isModifiable = false;
				this.selectedSupplier = null;
				this.$emit('supplier:unselected');
			}
			// if (isNil(code)) return;
			this.currSearch = '';
		},
		/**
		 * @param {String} search
		 */
		handleSearch(search) {
			if (search === '') return;
			this.currSearch = search;
			this.$emit('search', search);
		},
		/**
		 * @param {String} name
		 */
		handleSupplierNameChange(name) {
			if (isNil(name)) return;
			this.supplierPayload.name = name;
		},
		/**
		 * @param {Object} supplier
		 */
		setSupplierCandidate(supplier) {
			this.supplierCandidate = supplier;
		},
		// ADDRESS
		openAddSupplierModal() {
			this.editSupplierPayload = {};
			this.$set(this.editSupplierPayload, 'companyType', 0);
			this.supplierPayload = {};
			this.$set(this.supplierPayload, 'companyType', 0);
			this.isModifiable = false;
			this.isEditModalForm = false;
			const hasSearch = this.currSearch !== '';
			if (hasSearch) this.supplierPayload.name = this.currSearch;

			this.isAddressModalOpen = true;
		},
		async openEditSupplierModal() {
			if (isNil(this.selectedSupplier)) return;
			const { code } = this.selectedSupplier;
			await getSupplier(code)
				.then((record) => {
					this.supplierPayload = this.editSupplierPayload = record;
				})
				.catch((error) => {
					this.supplierPayload = {};
					this.selectedSupplier = null;
				});
			this.isEditModalForm = true;
			this.isAddressModalOpen = true;
			this.isModifiable = true;
		},
		handleSupplierModalClose() {
			this.editSupplierPayload = {};
			this.supplierPayload.name = '';
		},
		/**
		 * @param {Number} id - supplierID
		 */
		async handleDeleteSupplier(id) {
			// const { id } = this.editSupplierPayload;
			this.editSupplierPayload = {};
			this.emitChange({ id: 0, name: '', email: '', phone: '', contactName: '', companyType: 1 });
			await deleteSupplier(id);
			await this.initSuppliers();
			this.setSelectedSupplier(null);
			this.isAddressModalOpen = false;
		},
		/**
		 * @param {Object} address
		 */
		async handleAddressSubmit(supplier) {
			let required = false;
			if (isNil(supplier.name) || supplier.name.trim() === '') {
				this.createToastForMobile(this.FormMSG(5, 'Error'), this.FormMSG(4, 'Name is required'), '', 'danger');

				required = true;
			} else if (isNil(supplier.address) || (!isNil(supplier.address) && (!('state' in supplier.address) || !('city' in supplier.address)))) {
				this.createToastForMobile(this.FormMSG(5, 'Error'), this.FormMSG(6, 'Address is required'), '', 'danger');

				required = true;
			}
			if (required) return;

			if (this.isEditModalForm) {
				await updateSupplier({
					id: supplier.id,
					supplier: omit(['address'], supplier),
					address: supplier.address
				});

				await this.initSuppliers();
				this.setSelectedSupplier({
					id: parseInt(supplier.id),
					name: supplier.name
				});

				await this.manageBankAction(+supplier.id, supplier.banks);

				this.createToastForMobile(this.FormMSG(10, 'Success'), this.FormMSG(11, 'Supplier updated successfully'), '', 'success');
			} else {
				const { id, name } = await addSupplier({
					name: supplier.name || '',
					type: this.rendSupplierType
				});

				this.supplierPayload.name = name;
				await addAddressSupplier({
					id,
					supplier: omit(['address'], supplier),
					address: supplier.address
				});
				await this.initSuppliers();
				this.supplierPayload = {
					...supplier,
					id,
					name
				};
				await this.setSelectedSupplier({ id: parseInt(id), name });

				await this.manageBankAction(+id, supplier.banks);

				this.createToastForMobile(this.FormMSG(10, 'Success'), this.FormMSG(12, 'Supplier added successfully'), '', 'success');
			}

			this.clearEditMode();
		},
		clearEditMode() {
			this.editSupplierPayload = {};
			this.supplierPayload = {};
			this.isEditModalForm = false;
		},
		async manageBankAction(supplierId, banks) {
			for (const bank of banks) {
				await addUpdSupplierBankAccount(+bank.id, {
					supplierId,
					...bank
				}).catch((error) => {
					console.error({ error });
				});
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.actions_holder {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	button.map-btn {
		width: 32px;
		height: 32px;
		border-radius: 16px;
		margin-right: 12px;
		text-align: center;
	}
	.supplier_actions_btns {
		width: calc(100% - 40px);
	}
}
</style>

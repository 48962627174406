var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    {
      staticClass: "animated fadeIn d-flex flex-row w-100 px-4",
      class: _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
    },
    [
      _c(
        "div",
        { staticStyle: { width: "98%" } },
        [
          _c(
            "b-row",
            { staticClass: "w-100" },
            [
              _c(
                "b-col",
                { attrs: { sm: "3", md: "3", lg: "3", xl: "3" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(4, "Bank Name") } },
                    [
                      _c("b-form-input", {
                        class: {
                          "is-invalid": _vm.$v.bank.bankName.$error,
                        },
                        attrs: { type: "text", autocomplete: "off" },
                        on: {
                          keyup: function ($event) {
                            return _vm.handleInputChanged("bankName")
                          },
                        },
                        model: {
                          value: _vm.bank.bankName,
                          callback: function ($$v) {
                            _vm.$set(_vm.bank, "bankName", $$v)
                          },
                          expression: "bank.bankName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "4", md: "4", lg: "4", xl: "4" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(1, "IBAN") } },
                    [
                      _c("b-form-input", {
                        class: {
                          "is-invalid": _vm.$v.bank.iban.$error,
                        },
                        attrs: { type: "text", autocomplete: "off" },
                        on: {
                          keyup: function ($event) {
                            return _vm.handleInputChanged("iban")
                          },
                        },
                        model: {
                          value: _vm.bank.iban,
                          callback: function ($$v) {
                            _vm.$set(_vm.bank, "iban", $$v)
                          },
                          expression: "bank.iban",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "3", md: "3", lg: "3", xl: "3" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(3, "BIC") } },
                    [
                      _c("b-form-input", {
                        attrs: { type: "text", autocomplete: "off" },
                        on: {
                          keyup: function ($event) {
                            return _vm.handleInputChanged("bic")
                          },
                        },
                        model: {
                          value: _vm.bank.bic,
                          callback: function ($$v) {
                            _vm.$set(_vm.bank, "bic", $$v)
                          },
                          expression: "bank.bic",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "2", md: "2", lg: "2", xl: "2" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(2, "Currency") } },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.currencies },
                        on: {
                          change: function ($event) {
                            return _vm.handleInputChanged("currency", 1)
                          },
                        },
                        model: {
                          value: _vm.bank.currency,
                          callback: function ($$v) {
                            _vm.$set(_vm.bank, "currency", $$v)
                          },
                          expression: "bank.currency",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center",
          staticStyle: { width: "2%", "margin-left": "-10px" },
        },
        [
          _c(_vm.getLucideIcon("MinusCircle"), {
            tag: "component",
            staticClass: "cursor-pointer",
            staticStyle: { "margin-left": "-8px", "margin-top": "12px" },
            attrs: { color: "#EA4E2C", size: 18, "stroke-width": 3 },
            on: { click: _vm.removeItem },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }